#root {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  height: 100%;
}

html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
}

.MuiTableSortLabel-root,
.MuiTableSortLabel-root.MuiTableSortLabel-active,
.MuiTableSortLabel-root:hover,
.MuiTableSortLabel-icon {
  color: inherit !important;
}
